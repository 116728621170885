import React from "react";

import {
  extractUsernameFromMessage,
  getDateAndTime,
  getDifferenceBetweenCurrentDate,
  getFirstLettersOfName,
  showUserName,
} from "../../utils/helper";
import { Tooltip } from "antd";
import { Clock } from "../../utils/icons";

const Replies = ({ item, userEmailData }) => {
  let userName = showUserName(item.body, userEmailData);
  
  return (
    <div className="d-flex" style={{ gap: "5px" }}>
      <div>
        <span
          className="rounded-circle fw-bold text-white d-flex align-items-center justify-content-center"
          style={{
            width: "35px",
            height: "35px",
            background: "#497ed8",
            paddingTop: userName !== "Onboardify Team" ? "3px" : "0px",
          }}
        >
          {userName === "Onboardify Team" ? (
            <img
              src="/onboard123.svg"
              alt="No Preview"
              width={20}
              height={20}
              style={{ borderRadius: "50%" }}
            />
          ) : (
            getFirstLettersOfName(userName, item.body)
          )}
        </span>
      </div>
      <div>
        <div
          className="rounded-2 px-3 py-2"
          style={{ background: "#6f74900f" }}
        >
          <div className="text-primary">
            {showUserName(item.body, userEmailData)}
          </div>
          <div
            className="inc-reply-container"
            style={{ color: "#6F7490" }}
            dangerouslySetInnerHTML={{
              __html: extractUsernameFromMessage(item.body),
            }}
          />
        </div>
        <Tooltip title={getDateAndTime(item.created_at)} placement="top">
          <span
            style={{
              color: "#6F7490",
              marginTop: "5px",
              display: 'inline-block',
              cursor: 'default'
            }}
          >
            <Clock />{" "}
            <span style={{fontSize: '14px'}}> {getDifferenceBetweenCurrentDate(item.created_at)}</span>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default Replies;
