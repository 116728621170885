import { useEffect, useState } from "react";
import { Hero } from "../components/Hero";
import {
  getAllCustomerData,
  getAllProfileDataByUser,
} from "../apiservice/ApiService";
import { Select } from "antd";
import { EmptyReports } from "../common/EmptyReports";
import { ServiceReportViewChart } from "./component/ServiceReportViewChart";
import { Loader } from "../common/Loader";
import Pako from "pako";

export const Check = () => {
  const [serviceOptions, setServiceOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [allColumnTitle, setAllColumnTitle] = useState([]);
  const [allServiceData, setAllServiceData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [serviceReportViewData, setServiceReportViewData] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFilterForService, setSelectedFilterForService] = useState("");

  const getMonthYearAndDay = (dateString) => {
    const inputDate = new Date(dateString);

    // Get the full month name, day, and year
    const options = { year: "numeric", month: "long", day: "numeric" };
    const result = inputDate.toLocaleDateString("en-US", options);

    return result;
  };

  const getDayOnly = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Pad with leading zero if needed
    return day;
  };

  const decompressData = (compressedData) => {
    let binaryData = atob(compressedData);
    let uint8Array = new Uint8Array(
      binaryData.split("").map((c) => c.charCodeAt(0))
    );
    let decompressed = Pako.inflate(uint8Array, { to: "string" });
    return JSON.parse(decompressed);
  };

  const fetchProfiledata = async () => {
    setLoading(true);
    const tempFilteredData = [];
    let tempAllServiceData = [];
    const tempServiceOptions = [];
    const tempDateOptions = [];
    let resultServiceResponse = {};
    let noDataService = false;
    try {
      const response = await getAllProfileDataByUser();

      if (response.success) {
        ///set service options here
        if (response.data.response[0].services.length > 0) {
          let tempNewOptions = [];

          response.data.response[0].services.forEach((item) => {
            tempNewOptions.push({
              label: item.title,
              value: item.id,
              boardId: item.board_id,
            });
          });
          setSelectedFilterForService(
            JSON.parse(
              response.data.response[0].services[0].service_column_value_filter
            ).key
          );
          setServiceOptions(tempNewOptions);
          setSelectedRequest(tempNewOptions[0].value);
          setAllServiceData(response.data.response[0].services);
        }

        if (response.data.response.length > 0) {
          tempAllServiceData = response.data.response[0].services;
          let serviceFilterKeyData = JSON.parse(
            tempAllServiceData[0].service_column_value_filter
          );
          let valueArrayOfFilterKeyDataByUser = serviceFilterKeyData.value
            .split(",")
            .map((item) => item.trim())
            .filter((item) => item.length > 0);
          if (valueArrayOfFilterKeyDataByUser.length === 0) {
            noDataService = true;
          }

          serviceFilterKeyData.date_key = "creation_log__1";

          const serviceChartData = JSON.parse(
            tempAllServiceData[0].onboardify_service_report_view
          );

          if (serviceChartData === null) {
            noDataService = true;
          }

          if (
            serviceFilterKeyData.key === null ||
            serviceFilterKeyData.value === null ||
            serviceFilterKeyData.date_key === null
          ) {
            noDataService = true;
          }
          setAllServiceData(tempAllServiceData);

          if (tempAllServiceData.length > 0) {
            tempAllServiceData.forEach((item) => {
              tempServiceOptions.push({
                label: item.title,
                value: item.id,
                boardId: item.board_id,
              });
            });
          }

          let serviceResponse = await getAllCustomerData(
            tempServiceOptions[0].boardId
          );

          if (serviceResponse.success) {
            if (serviceResponse.data.response.hasOwnProperty("data")) {
              resultServiceResponse = serviceResponse.data.response;
            } else {
              resultServiceResponse = decompressData(
                serviceResponse.data.response
              );
            }
          }

          if (!serviceResponse.success) {
            noDataService = true;
          }
          if (serviceResponse.success && serviceChartData !== null) {
            setServiceReportViewData(serviceChartData);
            setSelectedRequest(tempServiceOptions[0].value);
            setAllColumnTitle(resultServiceResponse.data.boards[0].columns);

            if (
              serviceFilterKeyData.key !== null &&
              serviceFilterKeyData.value !== null
            ) {
              if (valueArrayOfFilterKeyDataByUser.length === 0) {
              }
              if (
                valueArrayOfFilterKeyDataByUser.length === 1 &&
                valueArrayOfFilterKeyDataByUser[0] === "All"
              ) {
                resultServiceResponse.data.boards[0].items_page.items.forEach(
                  (item) => {
                    tempFilteredData.push(item);
                  }
                );
              }
              if (
                valueArrayOfFilterKeyDataByUser.length === 1 &&
                valueArrayOfFilterKeyDataByUser[0] !== "All"
              ) {
                if (serviceFilterKeyData.key === "name") {
                  resultServiceResponse.data.boards[0].items_page.items.forEach(
                    (item) => {
                      if (
                        valueArrayOfFilterKeyDataByUser
                          .map((item) => item.toLowerCase())
                          .includes(item.name.toLowerCase())
                      ) {
                        tempFilteredData.push(item);
                      }
                    }
                  );
                } else {
                  resultServiceResponse.data.boards[0].items_page.items.forEach(
                    (item) => {
                      item.column_values.forEach((subItem) => {
                        if (
                          subItem.id === serviceFilterKeyData.key &&
                          subItem.text !== null &&
                          (valueArrayOfFilterKeyDataByUser
                            .map((item) => item.toLowerCase())
                            .includes(subItem.text.toLowerCase()) ||
                            subItem.text.includes(
                              valueArrayOfFilterKeyDataByUser[0]
                            ))
                        ) {
                          tempFilteredData.push(item);
                        }
                      });
                    }
                  );
                }
              }
              if (valueArrayOfFilterKeyDataByUser.length > 1) {
                if (serviceFilterKeyData.key === "name") {
                  resultServiceResponse.data.boards[0].items_page.items.forEach(
                    (item) => {
                      if (
                        valueArrayOfFilterKeyDataByUser
                          .map((item) => item.toLowerCase())
                          .includes(item.name.toLowerCase())
                      ) {
                        tempFilteredData.push(item);
                      }
                    }
                  );
                } else {
                  resultServiceResponse.data.boards[0].items_page.items.forEach(
                    (item) => {
                      item.column_values.forEach((subItem) => {
                        // console.log(subItem.text === 'PWC' || 'ADCB' ? subItem :'sd');
                        if (
                          subItem.id === serviceFilterKeyData.key &&
                          subItem.text !== null &&
                          valueArrayOfFilterKeyDataByUser
                            .map((item) => item.toLowerCase())
                            .includes(subItem.text.toLowerCase())
                        ) {
                          tempFilteredData.push(item);
                        }
                      });
                    }
                  );
                }
              }
            } else {
              noDataService = true;
            }

            if (tempFilteredData.length > 0) {
              tempFilteredData.forEach((item) => {
                item.column_values.forEach((subItem) => {
                  if (
                    subItem.id === serviceFilterKeyData.date_key &&
                    subItem.text !== null
                  ) {
                    let tempDataForColumnValues = [...item.column_values];
                    tempDataForColumnValues.push({
                      id: "name",
                      value: item.name,
                      text: item.name,
                    });
                    tempDateOptions.push({
                      label: getMonthYearAndDay(subItem.text),
                      value: getMonthYearAndDay(subItem.text),
                      day: getDayOnly(subItem.text),
                      data: tempDataForColumnValues,
                      name: item.name,
                    });
                  }
                });
              });
            } else {
              noDataService = true;
            }
          }

          let newDateDataOptions = reorderByDate(tempDateOptions, "value");

          let tempNewDateOptions = createGroupedItems(newDateDataOptions);
          tempNewDateOptions.unshift({ label: "All Time", value: "All Time" });
          setSelectedDate(tempNewDateOptions[0]);
          setDateOptions(tempNewDateOptions);
          setCurrentData(tempDateOptions[0].data);
          setPreviousData(tempDateOptions[1].data);
          setServiceOptions(tempServiceOptions);
        }
      }
    } catch (err) {
    } finally {
      setNoData(noDataService);
      setLoading(false);
    }
  };

  const getStatusCountForValueChart = (key, value) => {
    let count = 0;

    if (selectedDate.label === "All Time") {
      dateOptions.forEach((item) => {
        if (item.value !== "All Time") {
          item.data.forEach((subItem) => {
            subItem.data.forEach((detail) => {
              if (detail.id === key && detail.text === value) {
                count = count + 1;
              }
            });
          });
        }
      });
    } else {
      dateOptions.forEach((item) => {
        if (item.label === selectedDate.label) {
          item.data.forEach((subItem) => {
            subItem.data.forEach((detail) => {
              if (detail.id === key && detail.text === value) {
                count = count + 1;
              }
            });
          });
        }
      });
    }

    return count;
  };

  const getCountForStatusMultiValue = (column, subItem) => {
    let count = 0;

    if (selectedDate.label === "All Time") {
      subItem.selectedColumnsNewForStatus.forEach((detail) => {
        dateOptions.forEach((item) => {
          if (item.label !== "All Time") {
            item.data.forEach((subItem) => {
              subItem.data.forEach((subItemDetail) => {
                if (
                  subItemDetail.id === column &&
                  subItemDetail.text === detail
                ) {
                  count = count + 1;
                }
              });
            });
          }
        });
      });
    } else {
      subItem.selectedColumnsNewForStatus.forEach((detail) => {
        dateOptions.forEach((item) => {
          if (item.label === selectedDate.label) {
            item.data.forEach((subItem) => {
              subItem.data.forEach((subItemDetail) => {
                if (
                  subItemDetail.id === column &&
                  subItemDetail.text === detail
                ) {
                  count = count + 1;
                }
              });
            });
          }
        });
      });
    }

    return count;
  };

  const getBgColorForBarChart = (subItem, item) => {
    let hexColor = "#d20e0e";
    subItem.selectedColor.forEach((detail) => {
      if (detail.key === item) {
        hexColor = detail.value;
      }
    });
    hexColor = hexToRgba(hexColor, "1");
    return hexColor;
  };

  const getChartValueStatus = (item, subItem) => {
    let count = 0;
    let tempFilter = subItem.selectedFilter;
    let tempOptions = [];
    if (selectedDate.value === "All Time") {
      if (tempFilter !== undefined) {
        dateOptions.forEach((detail) => {
          if (detail.value !== "All Time") {
            detail.data.forEach((record) => {
              record.data.forEach((subRecord) => {
                if (subRecord.text !== null) {
                  if (
                    subRecord.id === selectedFilterForService &&
                    subRecord.text !== null &&
                    (subRecord.text === tempFilter ||
                      tempFilter.includes(subRecord.text) ||
                      subRecord.text.includes(tempFilter))
                  ) {
                    tempOptions.push(record.data);
                  }
                }
              });
            });
          }
        });
      }
    } else {
      const selectedDateMonth = selectedDate.label.split(" ")[0];
      if (tempFilter !== undefined) {
        dateOptions.forEach((detail) => {
          const recordMonth = detail.label.split(" ")[0];
          if (
            detail.value !== "All Time" &&
            recordMonth === selectedDateMonth
          ) {
            // console.log(detail , 'detail')

            detail.data.forEach((record) => {
              record.data.forEach((subRecord) => {
                if (subRecord.text !== null) {
                  if (
                    subRecord.id === selectedFilterForService &&
                    subRecord.text !== null &&
                    (subRecord.text === tempFilter ||
                      tempFilter.includes(subRecord.text) ||
                      subRecord.text.includes(tempFilter))
                  ) {
                    tempOptions.push(record.data);
                  }
                }
              });
            });
          }
        });
      }
    }

    if (tempFilter === undefined || tempFilter === null) {
      if (selectedDate.value === "All Time") {
        if (dateOptions.length > 0) {
          dateOptions.forEach((record) => {
            if (record.value !== "All Time") {
              record.data.forEach((detail) => {
                detail.data.forEach((subDetail) => {
                  if (subItem.selectedColumnsForStatus === subDetail.id) {
                    if (subDetail.text === item) {
                      count = count + 1;
                    }
                  }
                });
              });
            }
          });
        }
      } else {
        const selectedDateMonth = selectedDate.label.split(" ")[0];
        if (dateOptions.length > 0) {
          dateOptions.forEach((record) => {
            const recordMonth = record.label.split(" ")[0];

            if (recordMonth === selectedDateMonth) {
              record.data.forEach((detail) => {
                detail.data.forEach((subDetail) => {
                  if (subItem.selectedColumnsForStatus === subDetail.id) {
                    if (subDetail.text === item) {
                      count = count + 1;
                    }
                  }
                });
              });
            }
          });
        }
      }
    } else if (tempFilter === "") {
    } else if (tempFilter === "All") {
      if (selectedDate.value === "All Time") {
        if (dateOptions.length > 0) {
          dateOptions.forEach((record) => {
            if (record.value !== "All Time") {
              record.data.forEach((detail) => {
                detail.data.forEach((subDetail) => {
                  if (subItem.selectedColumnsForStatus === subDetail.id) {
                    if (subDetail.text === item) {
                      count = count + 1;
                    }
                  }
                });
              });
            }
          });
        }
      } else {
        const selectedDateMonth = selectedDate.label.split(" ")[0];
        if (dateOptions.length > 0) {
          dateOptions.forEach((record) => {
            const recordMonth = record.label.split(" ")[0];

            if (recordMonth === selectedDateMonth) {
              record.data.forEach((detail) => {
                detail.data.forEach((subDetail) => {
                  if (subItem.selectedColumnsForStatus === subDetail.id) {
                    if (subDetail.text === item) {
                      count = count + 1;
                    }
                  }
                });
              });
            }
          });
        }
      }
    } else {
      tempOptions.forEach((detail) => {
        detail.forEach((subDetail) => {
          if (subItem.selectedColumnsForStatus === subDetail.id) {
            // if('Active' === item){
            //   console.log(item , subDetail , 'sdf')
            // }
            if (subDetail.text === item) {
              count = count + 1;
            }
          }
        });
      });
    }

    return count;
  };

  const getMaxForVerticalBarChartStatus = (subItem) => {
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push(getChartValueStatus(item, subItem));
    });

    let chartMax = calculateChartMax(tempData);

    return chartMax;
  };

  const calculateStepSize = (data) => {
    // Convert string data to numbers
    const numericData = data.map(Number);

    // Find min and max values in the data
    const minValue = Math.min(...numericData);
    const maxValue = Math.max(...numericData);

    // Calculate the range
    const range = maxValue - minValue;

    // Determine a reasonable number of steps (e.g., 5 or 10 steps)
    const numberOfSteps = 2; // You can adjust this for more/less granularity

    // Calculate the raw stepSize by dividing the range by number of steps
    let stepSize = range / numberOfSteps;

    // Round stepSize up to the nearest multiple of 50
    stepSize = Math.ceil(stepSize / 10) * 10;

    return stepSize;
  };

  const calculateChartMax = (data) => {
    const numericData = data.map(Number);

    // Find the maximum value in the data
    const maxValue = Math.max(...numericData);

    // Calculate the stepSize
    const stepSize = calculateStepSize(data);

    // Calculate the chart max value, which is one stepSize above the max value
    const chartMax = Math.ceil(maxValue / stepSize) * stepSize + stepSize;

    return chartMax;
  };

  const getPieChartDataSetStatus = (subItem) => {
    let tempData = [];
    subItem.selectedColumns.forEach((item) => {
      tempData.push(getChartValueStatus(item, subItem));
    });
    // const newTempData = tempData.filter((item) => item !== 0);
    return tempData;
  };

  const getPieChartBgStatus = (subItem) => {
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push(getBgColorForBarChart(subItem, item));
    });
    return tempData;
  };

  const getPieChartLabelStatus = (subItem) => {
    let tempData = [];
    subItem.selectedColumns.forEach((item) => {
      tempData.push(item);
    });

    return tempData;
  };

  const getPieChartBorderStatus = (subItem) => {
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push("#fff");
    });
    return tempData;
  };

  const getRandomColor = () => {
    // Generate a random integer between 0 and 255
    return Math.floor(Math.random() * 256);
  };

  const hexToRgba = (hex, opacity = 1) => {
    // Check if hex is undefined or invalid
    if (!hex || typeof hex !== "string" || hex.length !== 7 || hex[0] !== "#") {
      // Return a random RGBA color
      return `rgba(${getRandomColor()}, ${getRandomColor()}, ${getRandomColor()}, ${opacity})`;
    }

    // Remove the '#' if it's there
    hex = hex.replace("#", "");

    // Parse the hex color
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Return the RGBA string with opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const getColumnTitleForTextChart = (id) => {
    let tempValue = "";

    allColumnTitle.forEach((item) => {
      if (item.id === id) {
        tempValue = item.title;
      }
    });

    return tempValue;
  };

  const getKeyFromAllColumn = (key) => {
    let tempValue;
    allColumnTitle.forEach((item) => {
      if (item.id === key) {
        tempValue = item.title;
      }
    });
    return tempValue;
  };

  const getTooltipData = (tempData) => {
    let tempCurrentArr = [];
    let tempPreviousArr = [];

    currentData.forEach((item) => {
      if (tempData.selectedColumns.includes(item.id)) {
        tempCurrentArr.push({
          key: getKeyFromAllColumn(item.id),
          value: item.text,
        });
      }
    });

    previousData.forEach((item) => {
      if (tempData.selectedColumns.includes(item.id)) {
        tempPreviousArr.push({
          key: getKeyFromAllColumn(item.id),
          value: item.text,
        });
      }
    });
    return { tempCurrentArr, tempPreviousArr };
  };

  const getBgSquareColor = (id, data) => {
    let tempColor = "#000000";
    data.forEach((item) => {
      if (item.key === id) {
        tempColor = item.value;
      }
    });
    return tempColor;
  };

  const getDescriptionForColumn = (column) => {
    let description = "";
    allColumnTitle.forEach((item) => {
      if (item.id === column) {
        if (item.hasOwnProperty("description") && item.description !== null) {
          description = item.description;
        } else {
          description = "";
        }
        // description = item.desc;
      }
    });

    if (description === undefined) {
      description = "";
    }
    return description;
  };

  const reorderByDate = (arr, key) => {
    const tempData = arr.sort((a, b) => {
      const creationLogA = a.data.find((item) => item.id === "creation_log__1");
      const creationLogB = b.data.find((item) => item.id === "creation_log__1");

      if (!creationLogA || !creationLogB) return 0; // If either log is missing, treat as equal

      const dateA = new Date(creationLogA.text);
      const dateB = new Date(creationLogB.text);

      return dateB - dateA; // Sort from latest to oldest
    });
    return tempData;
  };

  const createGroupedItems = (dataArray) => {
    const getMonthYearLabel = (label) => {
      const [month, , year] = label.split(" ");
      return `${month} ${year}`; // Format: "October 2024"
    };

    // Grouping by month
    const groupedByMonthYear = {};

    dataArray.forEach((item) => {
      const monthYearLabel = getMonthYearLabel(item.label);

      if (!groupedByMonthYear[monthYearLabel]) {
        groupedByMonthYear[monthYearLabel] = {
          label: monthYearLabel,
          value: monthYearLabel,
          data: [],
        };
      }
      groupedByMonthYear[monthYearLabel].data.push(item);
    });

    const result = Object.values(groupedByMonthYear);

    return result;
  };

  const handleChangeService = async (e) => {
    setLoading(true);
    setSelectedRequest(e);
    let resultServiceResponse = {};
    let tempSelectedServiceData = {};
    allServiceData.forEach((item) => {
      if (item.id === e) {
        tempSelectedServiceData = item;
        setSelectedFilterForService(
          JSON.parse(item.service_column_value_filter).key
        );
      }
    });

    let tempFilterKey = JSON.parse(
      tempSelectedServiceData.service_column_value_filter
    );

    tempFilterKey.date_key = "creation_log__1";
    let tempServiceViewData = JSON.parse(
      tempSelectedServiceData.onboardify_service_report_view
    );
    let tempBoardId = tempSelectedServiceData.board_id;
    let tempFilteredData = [];
    let tempDateOptions = [];

    if (
      tempFilterKey.key === null ||
      tempFilterKey.value === null ||
      tempFilterKey.date_key === null ||
      tempServiceViewData === null
    ) {
      setNoData(true);
      setLoading(false);
    } else {
      try {
        const serviceResponse = await getAllCustomerData(tempBoardId);

        if (serviceResponse.success) {
          if (serviceResponse.data.response.hasOwnProperty("data")) {
            resultServiceResponse = serviceResponse.data.response;
          } else {
            resultServiceResponse = decompressData(
              serviceResponse.data.response
            );
          }
        }


        if (!serviceResponse.success) {
          setNoData(true);
        } else {
          setServiceReportViewData(tempServiceViewData);
          setAllColumnTitle(resultServiceResponse.data.boards[0].columns);

          if (tempFilterKey.key !== null && tempFilterKey.value !== null) {
            let valueArrayOfFilterKeyDataByUser = tempFilterKey.value
              .split(",")
              .map((item) => item.trim())
              .filter((item) => item.length > 0);

            if (valueArrayOfFilterKeyDataByUser.length === 0) {
            }
            if (
              valueArrayOfFilterKeyDataByUser.length === 1 &&
              valueArrayOfFilterKeyDataByUser[0] === "All"
            ) {
              resultServiceResponse.data.boards[0].items_page.items.forEach(
                (item) => {
                  tempFilteredData.push(item);
                }
              );
            }
            if (
              valueArrayOfFilterKeyDataByUser.length === 1 &&
              valueArrayOfFilterKeyDataByUser[0] !== "All"
            ) {
              if (tempFilterKey.key === "name") {
                resultServiceResponse.data.boards[0].items_page.items.forEach(
                  (item) => {
                    if (
                      item.name !== null &&
                      valueArrayOfFilterKeyDataByUser
                        .map((item) => item.toLowerCase())
                        .includes(item.name.toLowerCase())
                    ) {
                      tempFilteredData.push(item);
                    }
                  }
                );
              } else {
                resultServiceResponse.data.boards[0].items_page.items.forEach(
                  (item) => {
                    item.column_values.forEach((subItem) => {
                      if (subItem.text !== null) {
                        if (
                          item.name !== null &&
                          subItem.id === tempFilterKey.key &&
                          (valueArrayOfFilterKeyDataByUser
                            .map((item) => item.toLowerCase())
                            .includes(subItem.text.toLowerCase()) ||
                            subItem.text.includes(
                              valueArrayOfFilterKeyDataByUser[0]
                            ))
                        ) {
                          tempFilteredData.push(item);
                        }
                      }
                    });
                  }
                );
              }
            }
            if (valueArrayOfFilterKeyDataByUser.length > 1) {
              if (tempFilterKey.key === "name") {
                resultServiceResponse.data.boards[0].items_page.items.forEach(
                  (item) => {
                    if (
                      valueArrayOfFilterKeyDataByUser
                        .map((item) => item.toLowerCase())
                        .includes(item.name.toLowerCase())
                    ) {
                      tempFilteredData.push(item);
                    }
                  }
                );
              } else {
                resultServiceResponse.data.boards[0].items_page.items.forEach(
                  (item) => {
                    item.column_values.forEach((subItem) => {
                      if (
                        subItem.id === tempFilterKey.key &&
                        subItem.text !== null &&
                        valueArrayOfFilterKeyDataByUser
                          .map((item) => item.toLowerCase())
                          .includes(subItem.text.toLowerCase())
                      ) {
                        tempFilteredData.push(item);
                      }
                    });
                  }
                );
              }
            }
          } else {
            // noDataService = true;
          }

          // if (tempFilterKey.key === "name") {
          //   serviceResponse.data.response.data.boards[0].items_page.items.forEach(
          //     (item) => {
          //       if (
          //         item.name.toLowerCase() === tempFilterKey.value.toLowerCase()
          //       ) {
          //         tempFilteredData.push(item);
          //       }
          //     }
          //   );
          // } else {
          //   serviceResponse.data.response.data.boards[0].items_page.items.forEach(
          //     (item) => {
          //       item.column_values.forEach((subItem) => {
          //         if (subItem.text !== null) {
          //           if (
          //             subItem.id === tempFilterKey.key &&
          //             subItem.text.toLowerCase() ===
          //               tempFilterKey.value.toLowerCase()
          //           ) {
          //             tempFilteredData.push(item);
          //           }
          //         }
          //       });
          //     }
          //   );
          // }

          if (tempFilteredData.length > 0) {
            tempFilteredData.forEach((item) => {
              item.column_values.forEach((subItem) => {
                if (subItem.text !== null) {
                  if (subItem.id === tempFilterKey.date_key) {
                    let tempMonthName = getMonthYearAndDay(subItem.text);
                    let tempDataForColumnValues = [...item.column_values];
                    tempDataForColumnValues.push({
                      id: "name",
                      value: item.name,
                      text: item.name,
                    });
                    tempDateOptions.push({
                      label: tempMonthName,
                      value: tempMonthName,
                      data: tempDataForColumnValues,
                      name: item.name,
                    });
                  }
                }
              });
            });

            if (tempDateOptions.length > 0) {
              setNoData(false);

              let newDateDataOptions = reorderByDate(tempDateOptions, "value");
              let tempNewDateOptions = createGroupedItems(newDateDataOptions);
              tempNewDateOptions.unshift({
                label: "All Time",
                value: "All Time",
              });
              setSelectedDate(newDateDataOptions[0]);
              setDateOptions(tempNewDateOptions);
              setCurrentData(tempDateOptions[0].data);

              if (newDateDataOptions.length > 1) {
                setPreviousData(tempDateOptions[1].data);
              } else {
                setPreviousData([]);
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangeDate = (e) => {
    dateOptions.forEach((item) => {
      if (e === item.value) {
        setSelectedDate(item);
      }
    });
  };

  const checkScreenWidth = () => {
    if (window.innerWidth < 1400) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  const getDataSetForVerticalBarChartStatus = (subItem) => {
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push({
        label: item,
        data: [getChartValueStatus(item, subItem)],
        backgroundColor: getBgColorForBarChart(subItem, item),
        borderColor: "transparent",
        borderRadius: {
          topLeft: 5, // Set the top-left corner radius
          topRight: 5, // Set the top-right corner radius
          bottomLeft: 0, // No radius for the bottom-left corner
          bottomRight: 0, // No radius for the bottom-right corner
        },
        borderSkipped: false,
        borderWidth: 1,
      });
    });

    return tempData;
  };

  const getDataSetForHorizontalBarChartStatus = (subItem) => {
    // console.log(subItem)
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push({
        label: item,
        data: [getChartValueStatus(item, subItem)],
        backgroundColor: getBgColorForBarChart(subItem, item),
        borderColor: "transparent",
        borderRadius: {
          topLeft: 0, // Set the top-left corner radius
          topRight: 5, // Set the top-right corner radius
          bottomLeft: 0, // No radius for the bottom-left corner
          bottomRight: 5, // No radius for the bottom-right corner
        },
        borderSkipped: false,
        borderWidth: 1,
      });
    });

    return tempData;
  };

  const getStepSizeForVerticalBarChartStatus = (subItem) => {
    let tempData = [];

    subItem.selectedColumns.forEach((item) => {
      tempData.push(getChartValueStatus(item, subItem));
    });

    let stepSize = calculateStepSize(tempData);

    return stepSize;
  };

  useEffect(() => {
    window.addEventListener("resize", checkScreenWidth);
    window.addEventListener("load", checkScreenWidth);
    checkScreenWidth();

    // Cleanup function to remove the listeners on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
      window.removeEventListener("load", checkScreenWidth);
    };
  }, []);

  useEffect(() => {
    fetchProfiledata();
  }, []);

  // Trigger GTM event
  useEffect(() => {
    // Ensure dataLayer exists
    setTimeout(() => {
    window.dataLayer = window.dataLayer || [];

    // Push event to dataLayer
    window.dataLayer.push({
      event: "Overall Status Page Loaded",
      page: window.location.pathname,
    });

  }, 1000); // Delay by 1 second
  }, []);

  return (
    <div style={{ padding: mobileView ? "0px" : "1rem" }}>
      <div style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        <Hero
          heading={"Overall Status"}
          subheading="Stay informed and in control of the overall status of your onboarding requests"
          forHome={true}
        />
      </div>
      {loading && <Loader />}
      {!mobileView ? (
        <div>
          <div
            style={{
              marginTop: "12px",
              padding: "24px",
              background: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #858b932E",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "33.6px",
                color: "#202223",
                fontFamily: "Graphie-SemiBold",
              }}
            >
              Reports
            </span>
            <div
              style={{
                display: "flex",
                gap: "20px",
                padding: "8px",
                borderRadius: "8px",
              }}
            >
              <Select
                placeholder="Select Service"
                onChange={handleChangeService}
                options={serviceOptions}
                value={selectedRequest || undefined}
              />
              <Select
                placeholder="Select Date"
                onChange={handleChangeDate}
                options={dateOptions}
                value={selectedDate || undefined}
                style={{ width: "100%", minWidth: "150px" }} // Adjust width for small screens
              />
            </div>
          </div>
          {noData ? (
            <EmptyReports />
          ) : (
            <ServiceReportViewChart
              serviceReportViewData={serviceReportViewData}
              getColumnTitleForTextChart={getColumnTitleForTextChart}
              getTooltipData={getTooltipData}
              previousData={previousData}
              getBgSquareColor={getBgSquareColor}
              getDescriptionForColumn={getDescriptionForColumn}
              mobileView={mobileView}
              getDataSetForVerticalBarChartStatus={
                getDataSetForVerticalBarChartStatus
              }
              getDataSetForHorizontalBarChartStatus={
                getDataSetForHorizontalBarChartStatus
              }
              getStepSizeForVerticalBarChartStatus={
                getStepSizeForVerticalBarChartStatus
              }
              getMaxForVerticalBarChartStatus={getMaxForVerticalBarChartStatus}
              getPieChartDataSetStatus={getPieChartDataSetStatus}
              getPieChartBgStatus={getPieChartBgStatus}
              getPieChartLabelStatus={getPieChartLabelStatus}
              getPieChartBorderStatus={getPieChartBorderStatus}
              getStatusCountForValueChart={getStatusCountForValueChart}
              getCountForStatusMultiValue={getCountForStatusMultiValue}
              selectedDate={selectedDate}
            />
          )}
        </div>
      ) : (
        <div>
          <div
            style={{
              marginTop: "12px",
              padding: "24px",
              background: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #858b932E",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              marginLeft: "20px",
              marginRight: "20px",
              flexWrap: "wrap", // Allow the content to wrap on smaller screens
            }}
          >
            <span
              style={{
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "33.6px",
                color: "#202223",
                fontFamily: "Graphie-SemiBold",
                flexBasis: "100%", // Span takes full width on small screens
                marginBottom: "12px",
              }}
            >
              Reports
            </span>
            <div
              style={{
                display: "flex",
                gap: "20px",
                padding: "8px",
                borderRadius: "8px",
                flexWrap: "wrap", // Allow the items inside to wrap
              }}
            >
              <Select
                placeholder="Select Service"
                onChange={handleChangeService}
                options={serviceOptions}
                value={selectedRequest || undefined}
                style={{ width: "100%", maxWidth: "200px" }} // Adjust width for small screens
              />

              <Select
                placeholder="Select Date"
                onChange={handleChangeDate}
                options={dateOptions}
                value={selectedDate || undefined}
                style={{ width: "100%", maxWidth: "200px" }} // Adjust width for small screens
              />
            </div>
          </div>
          {noData ? (
            <EmptyReports />
          ) : (
            <ServiceReportViewChart
              serviceReportViewData={serviceReportViewData}
              getColumnTitleForTextChart={getColumnTitleForTextChart}
              getTooltipData={getTooltipData}
              previousData={previousData}
              getBgSquareColor={getBgSquareColor}
              getDescriptionForColumn={getDescriptionForColumn}
              mobileView={mobileView}
              getDataSetForVerticalBarChartStatus={
                getDataSetForVerticalBarChartStatus
              }
              getDataSetForHorizontalBarChartStatus={
                getDataSetForHorizontalBarChartStatus
              }
              getStepSizeForVerticalBarChartStatus={
                getStepSizeForVerticalBarChartStatus
              }
              getMaxForVerticalBarChartStatus={getMaxForVerticalBarChartStatus}
              getPieChartDataSetStatus={getPieChartDataSetStatus}
              getPieChartBgStatus={getPieChartBgStatus}
              getPieChartLabelStatus={getPieChartLabelStatus}
              getPieChartBorderStatus={getPieChartBorderStatus}
              getStatusCountForValueChart={getStatusCountForValueChart}
              getCountForStatusMultiValue={getCountForStatusMultiValue}
              selectedDate={selectedDate}
            />
          )}
        </div>
      )}
    </div>
  );
};
