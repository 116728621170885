import { useLocation, useParams } from "react-router-dom";
import { BreadcrumbComponent } from "./component/BreadCrumbComponent";
import {
  getSubItemDetails,
  getAllProfileDataByUser,
} from "../apiservice/ApiService";
import { useEffect, useState } from "react";
import { countries } from "../utils/assets";
import { fetcher, formatDateNew, formatDateNewFormat } from "../utils/helper";
import { UpdateComponent } from "./component/UpdateComponent";
import { Loader } from "../common/Loader";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const TrackDetails = () => {
  const [itemDetails, setItemDetails] = useState({});
  const [subItemDetails, setSubItemDetails] = useState([]);
  const location = useLocation();
  const [likeIds, setLikeIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columnIdLoading, setColumnIdLoading] = useState(false);
  const [columnData, setColumnData] = useState("");
  const [subHeadingString, setSubHeadingString] = useState("");
  const breadCrumbData = location.pathname.replace('trackdetails', 'track').split("/");
  const { boardId, itemId, username } = useParams();
  const [status, setStatus] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [name, setName] = useState('');
  let selectedColumnData = "";

  const settingsData = JSON.parse(localStorage.getItem("settings")) || {
    image: "https://onboardify.tasc360.com/uploads/y22.png",
    site_bg: "#ffffff",
    button_bg: "#497ed8",
    banner_bg: "#497ed8",
    banner_content:
      "Hire an attitude, not just experience and qualification. Greg Savage.",
    header_bg: "#f7f7f7",
    head_title_color: "#497ed8",
  };

  const getStatusColor = (item) => {
    let tempBgColor = "#8080803b";
    settingsData.statusColorSetting.forEach((details) => {
      const newArr = details.status.map(item => item.trim().toLowerCase());

      if (newArr.includes(item.trim().toLowerCase())) {
        tempBgColor = details.color;
      }
    });
    setStatusColor(tempBgColor);
  };

  const getProfileData = async () => {
    try {
      const response = await getAllProfileDataByUser();

      return response;
    } catch (err) {
    } finally {
    }
  };

  const getTrackRequestData = async () => {
    setColumnIdLoading(true);
    try {
      const profileResponse = await getProfileData();
      if (profileResponse.success) {
        profileResponse.data.response[0].services.forEach((item) => {
          if (item.board_id == boardId) {
            setColumnData(JSON.parse(item.service_setting_data));
            selectedColumnData = JSON.parse(item.service_setting_data);
          }
        });
      }
    } catch (error) {
    } finally {
      setColumnIdLoading(false);
    }
  };

  useEffect(() => {
    getTrackRequestData();
  }, []);

  const fetchSubItemsDetails = async () => {
    setLoading(true);

    try {
      const response = await getSubItemDetails(itemId, boardId);
      await newFetchData();

      if (response.success) {
        let tempText = "";
        selectedColumnData.sub_headings_column.forEach((item, index) => {
          response.data.response.data.items[0].column_values.forEach(
            (subItem) => {
              if (subItem.id === item.id) {
                tempText = tempText + subItem.text;
                if (index < selectedColumnData.sub_headings_column.length - 1) {
                  tempText = tempText + " | ";
                }
              }
            }
          );
        });
        setSubHeadingString(tempText);
        setItemDetails(response.data.response.data);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getAllLikes = async () => {
    let ids = [];
    let likes = await fetcher(`incorpify/listAllLikes`, "GET");
    if (likes.success) {
      likes.data.map((item) => {
        ids.push(item.item_type_id);
      });
    } else {
      ids = [];
    }

    setLikeIds(ids);
  };

  const getCountryCode = (item) => {
    let countryName = "";
    itemDetails.items[0].column_values.forEach((subItem) => {
      if (subItem.id == item.id && subItem.type === "country") {
        countryName = subItem.text;
      }
    });

    let code = "";
    countries.forEach((subItem) => {
      if (countryName === subItem.countryname) {
        code = subItem.countrycode;
      }
    });
    return code;
  };

  const getCenterText = (item) => {
    let value = "";
    itemDetails.items[0].column_values.forEach((subItem) => {
      if (subItem.id === item.id) {
        if (subItem.hasOwnProperty("type") && subItem.type === "date") {
          value =
            subItem.text === ""
              ? subItem.text
              : formatDateNewFormat(subItem.text);
        } else {
          value = subItem.text;
        }
      }
    });

    return value;
  };

  const newFetchData = async () => {
    // setLoading(true);
    try {
      let url = `incorpify/getSubItemDetailsById/${itemId}`;
      let method = "GET";
      const response = await fetcher(url, method);
      if (response.success) {
        setName(response.data.response.data.items[0].name);
        response.data.response.data.items[0].column_values.forEach((item) => {
          if (item.id == selectedColumnData.required_columns.overall_status) {
            setStatus(item.text);
            getStatusColor(item.text);
          }
        });
        setSubItemDetails(response.data.response.data.items[0].column_values);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      // setLoading(false);
    }
  };

  const getInitialAction = (item) => {
    let tempInitialAction = "";

    subItemDetails.forEach((subItem) => {
      if (item.id === subItem.id) {
        if (subItem.text === null) {
          tempInitialAction = "";
        } else {
          tempInitialAction = subItem.text;
        }
      }
    });

    return tempInitialAction;
  };

  const getColor = (item) => {
    let tempColor = "";

    settingsData.statusColorSetting.forEach((detail) => {
      const newArr = detail.status.map((item) => item.trim().toLowerCase());

      if (newArr.includes(item.trim().toLowerCase())) {
        tempColor = detail.color;
      }
    });

    return tempColor;
  };

  const getInitialDate = () => {
    return formatDateNew(itemDetails.items[0].created_at);
  };

  const getUpdatedDate = (item) => {
    let updatedDate = "";
    const activityLogs = itemDetails.boards[0].activity_logs;
    for (let i = 0; i < activityLogs.length; i++) {
      const subItem = activityLogs[i];
      const tempData = JSON.parse(subItem.data);

      if (item.id === tempData.column_id) {
        if (tempData.hasOwnProperty("value") && tempData.value !== null) {
          updatedDate = formatDateNew(new Date(subItem.created_at / 10000));
        }
        break;
      }
    }

    return updatedDate;
  };

  const getDescription = (item) => {
    let tempDescription = "";
    itemDetails.boards[0].columns.forEach((detail) => {
      if (item.id === detail.id) {
        tempDescription = detail.description === null ? "" : detail.description;
      }
    });
    return tempDescription;
  };

  useEffect(() => {
    fetchSubItemsDetails();
  }, []);

  useEffect(() => {
    getAllLikes();
  }, []);

  // Trigger GTM event
    useEffect(() => {
      // Ensure dataLayer exists
      setTimeout(() => {
      window.dataLayer = window.dataLayer || [];
  
      // Push event to dataLayer
      window.dataLayer.push({
        event: "Track Request Detail Page Loaded",
        page: window.location.pathname,
      });
  
      // console.log("Login Page Loaded Event Fired!");
    }, 1000); // Delay by 1 second
    }, []);

  if (columnIdLoading) {
    return <Loader />;
  }

  return (
    <div className="onboardify-trackdetails-padding">
      {loading && <Loader />}
      <BreadcrumbComponent
        data={breadCrumbData}
        name={name}
      />
      <div className="onboardify-comment-information-container">
        <div className="onboardify-information-container">
          <div className="onboardify-information-icon-container">
            <div
              className="rounded-circle p-4 onboarding-rounded-circle"
              style={{ background: statusColor }}
            >
              <div
                className="icon-size text-light"
                style={{ height: "50px", width: "50px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.2"
                  baseProfile="tiny"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  style={{ maxWidth: "100%" }}
                  height="100%"
                >
                  <metadata fill="currentColor">
                    <sfw
                      xmlns="http://ns.adobe.com/SaveForWeb/1.0/"
                      fill="currentColor"
                    >
                      <slices fill="currentColor"></slices>
                      <slicesourcebounds
                        width="92.603"
                        height="88.807"
                        x="3.375"
                        y="-94.057"
                        bottomleftorigin="true"
                        fill="currentColor"
                      ></slicesourcebounds>
                    </sfw>
                  </metadata>
                  <path
                    d="M27.521,94c0.162-12,9.944-21.717,22.004-21.717C61.584,72.283,71.366,82,71.529,94h24.445c0,0,0.003,0.092,0.003,0.042  c0-25.572-20.73-46.206-46.301-46.206c-25.572,0-46.302,20.586-46.302,46.156C3.375,94.043,3.378,94,3.378,94H27.521z"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  ></path>
                  <circle
                    cx="49.677"
                    cy="24.856"
                    r="19.607"
                    fill="currentColor"
                  ></circle>
                </svg>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-around">
              <h5
                className="text-start m-0 card-user-name onboarding-fs-24"
                style={{
                  fontSize: "26px",
                  fontWeight: "700",
                  color: "#434343",
                }}
              >
                {name}
              </h5>
              <p
                className="profession m-0 text-start user-candidate-column onboarding-fs-14"
                style={{
                  fontWeight: "400",
                  fontSize: "17px",
                  color: "#928f8f",
                }}
              >
                {!loading && subHeadingString}
              </p>
              <h6
                className="fs-17 status m-0 text-start fw-bold"
                style={{ color: statusColor }}
              >
                {status}
              </h6>
            </div>
          </div>

          <div className="card border-0 border-1 p-4">
            <p
              className="column-head text-start head-color fw-bold pb-4 border-bottom onboarding-fs-20"
              style={{ fontSize: "26px" }}
            >
              Basic Information
            </p>
            <ul className="list-group list-group-flush">
              {Object.keys(columnData).length > 0 &&
                Object.keys(itemDetails).length > 0 &&
                columnData.candidate_coulmns.map((item, index) => {
                  const countryCode = getCountryCode(item);
                  const centerText = getCenterText(item);
                  if (centerText && centerText.length > 0) {
                    return (
                      <li
                        className="list-group-item d-flex align-items-start border-0 text-start mb-1"
                        style={{ background: "inherit", gap: "10px" }}
                        key={index}
                      >
                        <span
                          style={{ width: "20px", height: "20px" }}
                          className="text-warning mt-1 governify-category-icon-parent-new"
                        >
                          <i
                            className={item.icon}
                            style={{ color: "#928f8f" }}
                          ></i>
                        </span>
                        <div className="d-flex flex-column">
                          <span
                            className="text-secondary"
                            style={{ fontSize: "17px", color: "#928f8f" }}
                          >
                            {item.custom_title !== null &&
                              item.custom_title.length > 0 &&
                              item.custom_title}
                            {item.custom_title !== null &&
                              item.custom_title.length > 0 &&
                              ": "}
                            {centerText}
                            {(item.name === "Nationality" ||
                              item.name === "Country of Residency") && (
                              <span style={{ marginLeft: "5px" }}>
                                {countryCode.length > 0 && (
                                  <img
                                    height="20"
                                    width="22"
                                    src={`https://onboardify.tasc360.com/flags/${countryCode.toLowerCase()}.svg`}
                                    alt={`${countryCode.toUpperCase()} Flag`}
                                  />
                                )}
                              </span>
                            )}
                          </span>
                        </div>
                      </li>
                    );
                  }
                })}
            </ul>
          </div>

          <div className="card border-0 border-1 p-4">
            <h4 className="text-start head-color fw-bold pb-4 border-bottom onboarding-fs-20">
              Status Updates
            </h4>
            <ul className="list-group list-group-flush">
              {Object.keys(columnData).length > 0 &&
                Object.keys(itemDetails).length > 0 &&
                columnData.onboarding_columns.map((item, index) => {
                  const initialAction = getInitialAction(item);
                  const color = getColor(initialAction);
                  const updatedDate = getUpdatedDate(item);
                  const initialDate = getInitialDate();
                  const description = getDescription(item);

                  return (
                    <li
                      className="list-group-item d-flex align-items-start border-0 text-start mb-1"
                      style={{ background: "inherit", gap: "10px" }}
                      key={index}
                    >
                      <span
                        style={{ width: "20px", height: "20px" }}
                        className="text-warning mt-1"
                      >
                        <svg
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            maxWidth: "100%",
                            color: color === "" ? "#6C757D" : color,
                          }}
                          height="100%"
                        >
                          <path
                            d="M6 0c3.3137 0 6 2.6863 6 6s-2.6863 6-6 6-6-2.6863-6-6 2.6863-6 6-6zm2.6464 3.6464L5 7.293 3.3536 5.6464l-.7072.7072L5 8.707l4.3536-4.3535-.7072-.7072z"
                            fill="currentColor"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <div className="d-flex flex-column">
                        <span className="fw-bold text-secondary fs-5">
                          {item.name}{" "}
                          {description.length > 0 && (
                            <Tooltip placement="top" title={description}>
                              {" "}
                              <InfoCircleOutlined
                                style={{ fontSize: "14px" }}
                              />{" "}
                            </Tooltip>
                          )}
                        </span>
                        {initialAction !== "" && (
                          <span className="text-secondary">
                            {initialAction} |{" "}
                            {updatedDate === "" ? initialDate : updatedDate}
                          </span>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        {Object.keys(columnData).length > 0 && (
          <div className="col-6 d-flex flex-column onboarding-width onboardify-chat-container">
            <div
              className="card border-0 border-1 p-4"
              style={{ background: "rgba(111, 116, 144, 0.06)" }}
            >
              <p
                className="second-heading text-start head-color fw-bold pb-4 border-bottom"
                style={{ fontSize: "26px" }}
              >
                Request Updates
              </p>

              <UpdateComponent
                id={itemId}
                likeIds={likeIds}
                getAllLikes={getAllLikes}
                description={""}
                imageKey={columnData.image_key}
                columnIdData={columnData}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
