import { useLocation } from "react-router-dom";
import { Hero } from "../components/Hero";
import { BreadcrumbComponent } from "./component/BreadCrumbComponent";
import { SearchBox } from "../components/SearchBox";
import { useEffect, useState } from "react";
import { SortBy } from "./component/SortBy";
import { FilterBy } from "./component/FilterBy";
import { ExportBy } from "./component/ExportBy";
import { Button, Drawer, Radio } from "antd";
import { RequestComponent } from "./component/RequestComponent";
import {
  getAllProfileDataByUser,
  getAllFilters,
  getRequestTrackingDataByBoardIdAndSearch,
  getTrackingDataByBoardId,
  exportServiceData,
  requestTrackingWithOrCondition,
  getLastServiceUsedByUser,
  updateLastServiceUsedByUser,
  nextPageTrackItemData,
} from "../apiservice/ApiService";
import { Loader } from "../common/Loader";
import { FilterByService } from "./component/FilterByService";
import { CustomEmptyMessage } from "../common/CustomErrorComponent";
import { Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { toast, ToastContainer } from "react-toastify";

export const Track = () => {
  const location = useLocation();
  const breadCrumbData = location.pathname.split("/");
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [boardId, setBoardId] = useState("");
  const [data, setData] = useState([]);
  const [columnIdData, setColumnIdData] = useState({});
  const [allColumns, setAllColumns] = useState([]);
  const [originalArray, setOriginalArray] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(2);
  const [selectedFilter, setSelectedFilter] = useState("9");
  const [selectedService, setSelectedService] = useState(0);
  const [statusItems, setStatusItems] = useState([]);
  const [cursor, setCursor] = useState("");
  const [searchKeys, setSearchKeys] = useState([]);
  const [loadMoreValue, setLoadMoreValue] = useState(1);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [tempSearchData, setTempSearchData] = useState("");
  const [filterKeyData, setFilterKeyData] = useState({});
  const [placeHolderSearch, setPlaceHolderSearch] = useState("");
  const [profileId, setProfileId] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedExportColumns, setSelectedExportColumns] = useState([]);

  const onChangeRadio = (item) => {
    let tempSelectedOrder = "";
    if (item === "ASC") {
      tempSelectedOrder = 1;
      setSelectedOrder(1);
    }
    if (item === "DESC") {
      tempSelectedOrder = 2;
      setSelectedOrder(2);
    }
    let data = {
      order: tempSelectedOrder,
      boardId: boardId,
      statusFilter: selectedFilter,
      searchData: searchData,
    };
    getDataByFilterAndSearch(data);
  };

  const sortingItems = [
    {
      key: "1",
      label: (
        <Radio.Group
          onChange={() => onChangeRadio("ASC")}
          value={selectedOrder}
        >
          <Radio value={1}>Asc</Radio>
        </Radio.Group>
      ),
    },
    {
      key: "2",
      label: (
        <Radio.Group
          onChange={() => onChangeRadio("DESC")}
          value={selectedOrder}
        >
          <Radio value={2}>Desc</Radio>
        </Radio.Group>
      ),
    },
  ];

  const getFilterColumns = (items) => {
    let listOfStatus = JSON.parse(items.settings_str);

    let updatedFilterColumn = [
      {
        label: "All",
        key: "9",
      },
    ];
    let statusObject = listOfStatus.labels;

    Object.keys(statusObject).map((key) =>
      updatedFilterColumn.push({
        label: statusObject[key],
        key: parseInt(key, 10),
      })
    );

    setStatusItems(updatedFilterColumn);
  };

  const arrayToCsv = (data) => {
    return data.map((row) => row.join(",")).join("\n");
  };

  const handleExport = async () => {
    const processedFilterValue = filterKeyData.value
      .replace(/,$/, "")
      .split(", ")
      .filter((value) => value.trim() !== "")
      .map((value) => value);

    const payload = {
      query_params: {
        rules: processedFilterValue.map((item) => {
          return {
            column_id: filterKeyData.key,
            compare_value: item,
            operator: "contains_text",
          };
        }),
        operator: "or",
      },
    };

    setLoading(true);
    try {
      const data = [];
      // let tempColumns = [];
      const response = await exportServiceData(boardId, payload);

      if (response.success) {
        let columnWithLabels = Array(selectedExportColumns.length).fill("");
        response.data.response.data.boards[0].columns.forEach((subItem) => {
          if (selectedExportColumns.includes(subItem.id)) {
            let index = selectedExportColumns.indexOf(subItem.id);
            columnWithLabels[index] = subItem.title;
          }
        });

        data.push(columnWithLabels);
        response.data.response.data.boards[0].items_page.items.forEach(
          (item) => {
            let newTempData = Array(selectedExportColumns.length).fill("");

            let newItem = item.column_values;
            newItem.push({ id: "name", text: item.name, value: item.name });
            newItem.forEach((subItem) => {
              if (selectedExportColumns.includes(subItem.id)) {
                let index = selectedExportColumns.indexOf(subItem.id);
                newTempData[index] = subItem.text;
              }
            });
            data.push(newTempData);
          }
        );

        const csvData = arrayToCsv(data);

        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "ruu");
    } finally {
      setLoading(false);
      setOpenDrawer(false);
    }
  };

  const getProfileData = async () => {
    try {
      const response = await getAllProfileDataByUser();

      return response;
    } catch (err) {
    } finally {
    }
  };

  const getTrackData = async (tempBoardId, filterKeyDataByUser) => {
    if (tempBoardId === "") {
      return;
    }
    const rules = [];
    let valueArrayOfFilterKeyDataByUser = filterKeyDataByUser.value
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item.length > 0);

    if (
      Object.keys(filterKeyDataByUser).length > 0 &&
      filterKeyDataByUser.key.length > 0 &&
      valueArrayOfFilterKeyDataByUser.length > 0 &&
      !(
        valueArrayOfFilterKeyDataByUser.length === 1 &&
        valueArrayOfFilterKeyDataByUser[0] === "All"
      )
    ) {
      valueArrayOfFilterKeyDataByUser.forEach((detail) => {
        rules.push({
          column_id: filterKeyDataByUser.key,
          compare_value: [detail],
          operator: "contains_text",
        });
      });
    }

    const payload = {
      query_params: {
        order_by: [
          {
            direction: selectedOrder === 1 ? "asc" : "desc",
            column_id: "__creation_log__",
          },
        ],
        ...(rules.length > 0 && { groups: [{ rules: rules, operator: "or" }] }),
      },
    };

    try {
      const response = await getTrackingDataByBoardId(tempBoardId, payload);
      if (response.success) {
        if (response.data.response.data.boards[0].items_page.items.length > 0) {
          if (
            response.data.response.data.boards[0].items_page.items.length < 10
          ) {
            setData(response.data.response.data.boards[0].items_page.items);
            setCursor(null);
          } else {
            setData(
              response.data.response.data.boards[0].items_page.items.slice(
                0,
                10
              )
            );
            setOriginalArray(
              response.data.response.data.boards[0].items_page.items
            );
            setCursor(response.data.response.data.boards[0].items_page.cursor);
          }
          setAllColumns(response.data.response.data.boards[0].columns);
        }
        // setData(response.data.response.data.boards[0].items_page.items);
        // setCursor(response.data.response.data.boards[0].items_page.cursor);
      } else {
        if (
          response.data.response.data.boards[0].items_page.items.length === 0
        ) {
          setData([]);
        }
      }
    } catch (err) {
    } finally {
      setSelectedFilter("9");
    }
  };

  const getStatusFilterData = async (tempBoardId) => {
    try {
      const response = await getAllFilters(tempBoardId);
      if (response.success) {
        getFilterColumns(response.data.response.data.boards[0].columns[0]);
      }
    } catch (err) {
    } finally {
    }
  };

  const getPreviousSelectedService = async () => {
    try {
      const response = await getLastServiceUsedByUser();

      return response;
    } catch (err) {
    } finally {
    }
  };

  const updateSelectedService = async (data) => {
    try {
      const response = await updateLastServiceUsedByUser(data);
    } catch (err) {
    } finally {
    }
  };

  const getTrackRequestData = async () => {
    let tempBoardId = "";
    let filterKeyDataByUser = {};
    setLoading(true);
    try {
      const lastServiceResponse = await getPreviousSelectedService();

      const profileResponse = await getProfileData();
      if (profileResponse.success) {
        let tempData = [];
        profileResponse.data.response[0].services.forEach((item, index) => {
          tempData.push({
            label: item.title,
            boardId: item.board_id,
            value: item.id,
            key: index,
          });
        });
        setServiceOptions(tempData);
        setProfileData(profileResponse.data.response[0].services);
        setProfileId(profileResponse.data.response[0].id);

        if (lastServiceResponse.success) {
          tempData.forEach((detail) => {
            if (
              detail.value.toString() ===
              lastServiceResponse.data.response[0].service_id
            ) {
              setSelectedService(detail.key);
              setBoardId(detail.boardId);
              tempBoardId = detail.boardId;
            }
          });

          if (tempBoardId === "") {
            setSelectedService(tempData[0].key);
            setBoardId(tempData[0].boardId);
            tempBoardId = tempData[0].boardId;
          }
        } else {
        }

        profileResponse.data.response[0].services.forEach((item) => {
          if (item.board_id === tempBoardId) {
            if (JSON.parse(item.service_column_value_filter).value.length > 0) {
              filterKeyDataByUser = JSON.parse(
                item.service_column_value_filter
              );
              setFilterKeyData(filterKeyDataByUser);
            }

            setColumnIdData(JSON.parse(item.service_setting_data));
            setSearchKeys(
              JSON.parse(item.service_setting_data).required_columns.profession
            );
          }
        });
      }

      await getTrackData(tempBoardId, filterKeyDataByUser);
      await getStatusFilterData(tempBoardId);
      if (
        filterKeyDataByUser.value === undefined ||
        filterKeyDataByUser.value.length === 0
      ) {
        setData([]);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const setPlaceHolderText = () => {
    let text = "Search by ";
    if (
      Object.keys(columnIdData).length > 0 &&
      Object.keys(columnIdData.required_columns.profession).length > 0 &&
      allColumns.length > 0
    ) {
      allColumns.forEach((item) => {
        if (columnIdData.required_columns.profession.includes(item.id)) {
          text = text + item.title + " or ";
        }
      });
      text = text.slice(0, -4);
      setPlaceHolderSearch(text);
    } else {
      setPlaceHolderSearch("Search here");
    }
  };

  const loadMoreHandler = async () => {
    setLoading(true);
    try {
      const response = await nextPageTrackItemData(
        JSON.stringify({ cursor: cursor, limit: 10 })
      );
      if (response.success) {
        let tempData = [
          ...data,
          ...response.data.response.next_items_page.items,
        ];
        setData(tempData);
        setCursor(response.data.response.next_items_page.cursor);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getDataByFilterAndSearch = async (tempFilters) => {
    const rules = [];
    const filterRules = [];
    let valueArrayOfFilterKeyDataByUser = filterKeyData.value
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item.length > 0);
    if (tempFilters.statusFilter != 9) {
      filterRules.push({
        column_id: columnIdData.required_columns.overall_status,
        compare_value: [Number(tempFilters.statusFilter)],
      });
    }

    if (tempFilters.searchData.length > 0) {
      searchKeys.forEach((item) => {
        rules.push({
          column_id: item,
          compare_value: [tempFilters.searchData],
          operator: "contains_text",
        });
      });
    } else {
      if (
        Object.keys(filterKeyData).length > 0 &&
        filterKeyData.key.length > 0 &&
        valueArrayOfFilterKeyDataByUser.length > 0 &&
        !(
          valueArrayOfFilterKeyDataByUser.length === 1 &&
          valueArrayOfFilterKeyDataByUser[0] === "All"
        )
      ) {
        valueArrayOfFilterKeyDataByUser.forEach((detail) => {
          rules.push({
            column_id: filterKeyData.key,
            compare_value: [detail],
            operator: "contains_text",
          });
        });
      }
    }

    const payload = {
      query_params: {
        order_by: [
          {
            direction: tempFilters.order === 1 ? "asc" : "desc",
            column_id: "__creation_log__",
          },
        ],
        ...(filterRules.length > 0 || rules.length > 0
          ? {
              groups: [
                ...(filterRules.length > 0 ? [{ rules: filterRules }] : []),
                ...(rules.length > 0 ? [{ rules: rules, operator: "or" }] : []),
              ],
            }
          : {}),
      },
    };

    setLoading(true);

    try {
      const response = await getRequestTrackingDataByBoardIdAndSearch(
        tempFilters.boardId,
        JSON.stringify(payload)
      );
      const arr1 = response.data.response.data.boards[0].items_page.items;

      if (arr1.length > 0) {
        if (arr1.length < 10) {
          setData(response.data.response.data.boards[0].items_page.items);
          setCursor(null);
        } else {
          setData(arr1.slice(0, 10));
          setOriginalArray(arr1);
          setCursor(response.data.response.data.boards[0].items_page.cursor);
        }
      } else {
        setData([]);
        setOriginalArray([]);
        setCursor(null);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleChangeExportCheckBox = (e) => {
    if (e.target.checked) {
      if (
        columnIdData.hasOwnProperty("export_setting_data") &&
        columnIdData.export_setting_data.length > 0
      ) {
        setSelectedExportColumns(columnIdData.export_setting_data);
      }
    } else {
      setSelectedExportColumns([]);
    }
  };

  const handleChangeSingleExportCheckBox = (e, item) => {
    if (e.target.checked) {
      if (!selectedExportColumns.includes(item)) {
        let tempSelectedExportColumns = [...selectedExportColumns];
        tempSelectedExportColumns.push(item);
        setSelectedExportColumns(tempSelectedExportColumns);
      }
    } else {
      let tempSelectedExportColumns = selectedExportColumns.filter(
        (subItem) => subItem !== item
      );

      setSelectedExportColumns(tempSelectedExportColumns);
    }
  };

  const getColumnTitle = (id) => {
    let title = "";
    allColumns.forEach((item) => {
      if (item.id === id) {
        title = item.title;
      }
    });

    return title;
  };

  useEffect(() => {
    getTrackRequestData();
  }, []);

  useEffect(() => {
    setPlaceHolderText();
  }, [profileData, allColumns]);

  // Trigger GTM event
    useEffect(() => {
      // Ensure dataLayer exists
      setTimeout(() => {
      window.dataLayer = window.dataLayer || [];
  
      // Push event to dataLayer
      window.dataLayer.push({
        event: "Track Page Loaded",
        page: window.location.pathname,
      });
  
    }, 1000); // Delay by 1 second
    }, []);

  return (
    <div style={{ padding: "2rem" }}>
      {loading && <Loader />}
      <div style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        <Hero
          heading={"Request Tracking"}
          subheading="Track your onboarding progress effortlessly by using our request-tracking center"
          forHome={true}
        />
      </div>
      <BreadcrumbComponent data={breadCrumbData} />
      <SearchBox
        placeHolder={placeHolderSearch}
        setSearchData={setSearchData}
        getDataByFilterAndSearch={getDataByFilterAndSearch}
        boardId={boardId}
        order={selectedOrder}
        selectedFilter={selectedFilter}
        flag={true}
        tempSearchData={tempSearchData}
        filterKeyData={filterKeyData}
        setTempSearchData={setTempSearchData}
        getTrackData={getTrackData}
        setLoading={setLoading}
      />
      <div className="onboardify-filter-sort-container">
        <div className="onboardify-service-sort">
          <FilterByService
            items={serviceOptions}
            setSelectedService={setSelectedService}
            setBoardId={setBoardId}
            getDataByFilterAndSearch={getDataByFilterAndSearch}
            order={selectedOrder}
            selectedFilter={selectedFilter}
            searchData={searchData}
            profileData={profileData}
            setColumnIdData={setColumnIdData}
            setSearchKeys={setSearchKeys}
            setSearchData={setSearchData}
            tempSearchData={tempSearchData}
            setTempSearchData={setTempSearchData}
            getTrackData={getTrackData}
            setLoading={setLoading}
            setSelectedFilter={setSelectedFilter}
            filterKeyData={filterKeyData}
            setFilterKeyData={setFilterKeyData}
            updateSelectedService={updateSelectedService}
            profileId={profileId}
            selectedService={selectedService}
            getStatusFilterData={getStatusFilterData}
          />
          <SortBy
            items={sortingItems}
            getDataByFilterAndSearch={getDataByFilterAndSearch}
          />
        </div>
        <div className="onboardify-filter-export">
          <FilterBy
            items={statusItems}
            setSelectedFilter={setSelectedFilter}
            getDataByFilterAndSearch={getDataByFilterAndSearch}
            boardId={boardId}
            order={selectedOrder}
            selectedFilter={selectedFilter}
            searchData={searchData}
          />
          <ExportBy setOpenDrawer={setOpenDrawer} />
        </div>
      </div>
      {!loading && (
        <RequestComponent
          data={data}
          boardId={boardId}
          columnIdData={columnIdData}
          allColumns={allColumns}
          profileData={profileData}
        />
      )}
      {!loading && data.length === 0 && <CustomEmptyMessage />}
      {cursor !== null && !loading && data.length !== 0 && (
        <div>
          <Button onClick={loadMoreHandler}>Load More</Button>
        </div>
      )}

      <Drawer
        placement={"right"}
        width={800}
        closable={false}
        onClose={onCloseDrawer}
        open={openDrawer}
        zIndex={9991}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Content Section */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingBottom: "24px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  fontFamily: "Graphie-SemiBold",
                  color: "#202223",
                }}
              >
                Export
              </span>
              <span>
                <Button
                  icon={<CloseOutlined />}
                  iconPosition="end"
                  type="text"
                  onClick={() => setOpenDrawer(false)}
                ></Button>
              </span>
            </div>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Graphie-Light",
                color: "#6d7175",
              }}
            >
              Please select the information you would like to export as an excel
              sheet.
            </span>
          </div>

          {/* Checkbox Section */}
          {columnIdData.hasOwnProperty("export_setting_data") &&
            columnIdData.export_setting_data.length > 0 && (
              <div
                style={{
                  paddingBottom: "14px",
                  borderBottom: "1px solid #E1E1E1",
                }}
              >
                <Checkbox
                  onChange={handleChangeExportCheckBox}
                  checked={
                    selectedExportColumns.length ===
                    columnIdData.export_setting_data.length
                  }
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      fontFamily: "Graphie-Book",
                    }}
                  >
                    Select all
                  </span>
                </Checkbox>
              </div>
            )}

          {columnIdData.hasOwnProperty("export_setting_data") &&
            columnIdData.export_setting_data.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  paddingTop: "10px",
                }}
              >
                {columnIdData.hasOwnProperty("export_setting_data") &&
                  columnIdData.export_setting_data.length > 0 &&
                  columnIdData.export_setting_data.map((item) => {
                    return (
                      <div style={{ width: "50%", paddingBottom: "20px" }}>
                        <Checkbox
                          onChange={(e) =>
                            handleChangeSingleExportCheckBox(e, item)
                          }
                          checked={selectedExportColumns.includes(item)}
                        >
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              fontFamily: "Graphie-Book",
                            }}
                          >
                            {getColumnTitle(item)}
                          </span>
                        </Checkbox>
                      </div>
                    );
                  })}
              </div>
            )}

          {/* Button Section at the bottom */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              padding: "10px 0 0 0",
              marginTop: "auto",
            }}
          >
            <Button
              style={{ height: "44px" }}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Graphie-Light",
                  color: "#202223",
                }}
              >
                Cancel
              </span>
            </Button>
            <Button
              id="export_btn"
              style={{ height: "44px" }}
              type="primary"
              onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "Export Button Click",
                  buttonID: "export_btn",
                });
                handleExport();
              }}
              disabled={selectedExportColumns.length === 0}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Graphie-Light",
                }}
              >
                Export
              </span>
            </Button>
          </div>
        </div>
      </Drawer>
      <ToastContainer position="bottom-right" />
    </div>
  );
};
