import { Button } from "antd";
import { ExportIcon } from "../../utils/icons";

export const ExportBy = ({ setOpenDrawer }) => {
  return (
    <>
      <Button
        id="export_filter_btn"
        style={{ display: "flex", alignItems: "center", gap: "5px" }}
        type="text"
        className="governify-sortby-button"
        onClick={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "Export Filter Button Click",
            buttonID: "export_filter_btn",
          });
          setOpenDrawer(true);
        }}
      >
        {" "}
        <span>
          <ExportIcon />
        </span>
        <span className="fs-16 text-color-928f8f">Export</span>{" "}
      </Button>
    </>
  );
};
