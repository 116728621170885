import React, { useEffect } from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { isTokenValid } from "../utils/helper";

export const Layout = () => {
  const location = useLocation();
  const isCheckPage = location.pathname.includes("/check");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      let status = isTokenValid(token);
      if (status.valid) {
      } else {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div style={{ background: isCheckPage ? "#f2f2f7" : "white" }}>
      <Header />
      <div
        className="container d-flex flex-column h-100 text-center"
        style={{ minHeight: "65vh" }}
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
