import {
  InfoCircleOutlined,
} from "@ant-design/icons";
import { BarChartHorizontal } from "../../common/BarChartHorizontal";
import { BarChartVertical } from "../../common/BarChartVertical";
import { PieChart } from "../../common/PieChart";
import { Button, Tooltip } from "antd";
import { CustomTooltip } from "./CustomTooltip";
import { ChartColumnSvg, ChartPieSvg, ExportReportViewIcon } from "../../utils/icons";
import generatePDF from 'react-to-pdf';

export const ServiceReportViewChart = ({
  serviceReportViewData,
  getColumnTitleForTextChart,
  getTooltipData,
  previousData,
  getBgSquareColor,
  getDescriptionForColumn,
  mobileView,
  getDataSetForVerticalBarChartStatus,
  getStepSizeForVerticalBarChartStatus,
  getMaxForVerticalBarChartStatus,
  getPieChartDataSetStatus,
  getPieChartBgStatus,
  getPieChartLabelStatus,
  getPieChartBorderStatus,
  getStatusCountForValueChart,
  getCountForStatusMultiValue ,
  getDataSetForHorizontalBarChartStatus,
  selectedDate
}) => {
  const settingData = JSON.parse(localStorage.getItem("settings")) || {
    image: "https://onboardify.tasc360.com/uploads/y22.png",
    site_bg: "#ffffff",
    button_bg: "#497ed8",
    banner_bg: "#497ed8",
    banner_content:
      "Hire an attitude, not just experience and qualification. Greg Savage.",
    header_bg: "#f7f7f7",
    head_title_color: "#497ed8",
  };

  const options = {
    filename: 'onboardifyreport.pdf',  // Set the filename here
 };


  const getTargetElement = () => {
    const dateElement = document.getElementById('date-element');
    if(selectedDate.value === 'All Time'){
      dateElement.innerText = '';
    }else{
      dateElement.innerText = selectedDate.value;
    }
    return  document.getElementById('content-id');
  }


  return (
    <div>
      <div style={{marginTop:"10px" , padding:"24px", paddingBottom:"0px" , width:"100%" , textAlign:"right"}}>
            <Button
              id="download_report_btn"
              style={{
                background: settingData.button_bg,
                color: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: 600,
                height: "35px",
              }}
              icon={<ExportReportViewIcon />}
              iconPosition="end"
              onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "Download Report Button Click",
                  buttonID: "download_report_btn",
                });
                generatePDF(getTargetElement , options);
                const dateElement = document.getElementById('date-element');
                dateElement.innerText = '';
              }}
            >
              <span
                style={{
                  fontFamily: "Graphie-SemiBold",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Download Report
              </span>
            </Button>
            </div>
    <div style={{ marginTop: "20px" }}>
      {mobileView ? (
        <div id="content-id">
          {serviceReportViewData.map((item) => {
            return (
              <div style={{ marginTop: "20px" }}>
                 <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#818181",
                      textAlign: "left",
                      marginBottom: "0px",
                      fontFamily: "Graphie-SemiBold",
                    }}
                  >
                    {item.title}
                  </p>
                  <p
                  id='date-element'
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#818181",
                      fontFamily: "Graphie-SemiBold",
                      textAlign: "right",
                      paddingRight:"10px" ,
                      marginBottom:"0px" ,
                     
                    }}
                  >
                  </p>
                </div>
               
                <div
                  style={{
                    position: "relative",
                    display: "block",
                    width: "100%",
                  }}
                >
                  {item.boxes.map((subItem) => {
                    const commonStyles = {
                      width: "100%", // Full width for responsiveness
                      background: "white",
                      border: "1px solid #E3E3E3",
                      borderRadius: "8px",
                      padding: "10px",
                      marginBottom: "20px", // Adjusted margin for spacing
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative", // Use relative for block layout
                    };

                    if (subItem.type === "Bar Chart") {
                             
                      const dataset = subItem.horizontal ? getDataSetForHorizontalBarChartStatus(subItem).sort((a, b) => a.data[0] - b.data[0]) : getDataSetForVerticalBarChartStatus(subItem).sort((a, b) => a.data[0] - b.data[0]);
                      const dataNotPresent = dataset.every(item => item.data[0] === 0);

                      return (
                        <div style={{ ...commonStyles, height: dataNotPresent ?'300px':'auto' , justifyContent:dataNotPresent ? 'center':'flex-end' }}>
                      {subItem.horizontal ? dataNotPresent ? <div style={{maxWidth:"190px"}}>
                            <div
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  lineHeight: "33.6px",
                                  color: "#202223",
                                  textAlign: "left",
                                  fontFamily: "Graphie-Regular",
                                }}
                              >
                                {subItem.heading}
                              </span>
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip description={subItem.description} />
                                )}
                              </span>
                            </div>
                            <div>
                              <div><ChartColumnSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" , fontFamily: "Graphie-Light" }}>Once you have data, it will be displayed in this chart.</p>
                            </div>
                          </div> :(
                            <BarChartHorizontal
                              dataset={dataset}
                              stepsize={getStepSizeForVerticalBarChartStatus(
                                subItem
                              )}
                              max={getMaxForVerticalBarChartStatus(subItem)}
                              title={subItem.heading}
                              description={subItem.description}
                              toolTipData={getTooltipData(subItem)}
                              previousData={previousData}
                              mobileView={mobileView}
                            />
                          ) : (
                            dataNotPresent ? <div style={{maxWidth:"190px"}}>
                            <div
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  lineHeight: "33.6px",
                                  color: "#202223",
                                  textAlign: "left",
                                  fontFamily: "Graphie-Regular",
                                }}
                              >
                                {subItem.heading}
                              </span>
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip description={subItem.description} />
                                )}
                              </span>
                            </div>
                            <div>
                              <div><ChartColumnSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" , fontFamily: "Graphie-Light" }}>Once you have data, it will be displayed in this chart.</p>
                            </div>
                              
                             
                          </div> :
                            <BarChartVertical
                              dataset={dataset}
                              stepsize={getStepSizeForVerticalBarChartStatus(
                                subItem
                              )}
                              max={getMaxForVerticalBarChartStatus(subItem)}
                              title={subItem.heading}
                              description={subItem.description}
                              toolTipData={getTooltipData(subItem)}
                              previousData={previousData}
                            />
                          )}
    
                        </div>
                      );
                    }

                    if (subItem.type === "Pie Chart") {
                      const title = subItem.heading;
                      const dataset = getPieChartDataSetStatus(subItem);
                      const bgSet = getPieChartBgStatus(subItem);
                      const pieChartLabel = getPieChartLabelStatus(subItem);
                      const borderColorSetPie =
                        getPieChartBorderStatus(subItem);
                      const description = subItem.description;

                      return (
                        <div style={{ ...commonStyles, height: dataset.length === 0 ?'300px':'auto' }}>
                        {dataset.length === 0 ? <div style={{maxWidth:"190px"}}>
                            <div
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  lineHeight: "33.6px",
                                  color: "#202223",
                                  textAlign: "left",
                                  fontFamily: "Graphie-Regular",
                                }}
                              >
                                {subItem.heading}
                              </span>
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip description={subItem.description} />
                                )}
                              </span>
                            </div>
                            <div>
                              <div><ChartPieSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" , fontFamily: "Graphie-Light" }}>Once you have data, it will be displayed in this chart.</p>
                            </div>
                          </div>:
                          <PieChart
                            title={title}
                            dataset={dataset}
                            bgSet={bgSet}
                            pieChartLabel={pieChartLabel}
                            borderColorSetPie={borderColorSetPie}
                            description={description}
                            mobileView={mobileView}
                          /> }
                        </div>
                      );
                    }

                    if (subItem.type === "Value Chart") {
                      const description = getDescriptionForColumn(
                        subItem.column
                      );
                      const valueChartCount = getStatusCountForValueChart(
                        subItem.selectedColumnsForStatus,
                        subItem.column
                      );

                      return (
                        <div style={commonStyles}>
                           {valueChartCount === 0 ?  <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  color: "#6d7175",
                                  marginBottom: "6px",
                                  // fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                {subItem.column}{" "}
                                {getColumnTitleForTextChart(
                                  subItem.selectedColumnsForStatus
                                )}
                                <span>
                                  {description.length > 0 && (
                                    <Tooltip
                                      placement="top"
                                      title={description}
                                    >
                                      {" "}
                                      <InfoCircleOutlined
                                        style={{ fontSize: "14px" }}
                                      />{" "}
                                    </Tooltip>
                                  )}
                                </span>
                              </p>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "24px",
                                  fontWeight: "600",
                                  color: "#202223",
                                  marginBottom: "6px",
                                  fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                <p><ChartColumnSvg/> <span  style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</span></p>
                              </p>
                            </div>
                          </div>:
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  color: "#6d7175",
                                  marginBottom: "6px",
                                  // fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                {subItem.column}{" "}
                                {getColumnTitleForTextChart(
                                  subItem.selectedColumnsForStatus
                                )}
                                <span>
                                  {description.length > 0 && (
                                    <Tooltip
                                      placement="top"
                                      title={description}
                                    >
                                      {" "}
                                      <InfoCircleOutlined
                                        style={{ fontSize: "14px" }}
                                      />{" "}
                                    </Tooltip>
                                  )}
                                </span>
                              </p>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "24px",
                                  fontWeight: "600",
                                  color: "#202223",
                                  marginBottom: "6px",
                                  fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                {valueChartCount}
                              </p>
                            </div>
                          </div> }
                       
                        </div>
                      );
                    }

                    if (subItem.type === "Multi Value Chart") {
                      const allCountsAreZero = subItem.selectedColumnsNewForStatus.every(
                        (column) => getCountForStatusMultiValue(subItem.selectedColumns, subItem) === 0
                      );
                      return (
                        <div style={commonStyles}>
                           <div
                            style={{
                              width: "70%",
                              borderBottom: allCountsAreZero ?'' :
                                "1px solid rgba(201, 204, 207, 0.7)",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "24px",
                                fontWeight: "700",
                                color: "#202223",
                                fontFamily: "Graphie-Regular",
                                borderBottom: allCountsAreZero ?'1px solid rgba(201, 204, 207, 0.7)':
                                "",
                              }}
                            >
                              {subItem.heading}
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip
                                    description={subItem.description}
                                  />
                                )}
                              </span>
                            </p>
                          </div>
                          {allCountsAreZero ?   <div  style={{maxWidth:"190px"}}>
                              <div><ChartPieSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" ,fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" , fontFamily: "Graphie-Light" }}>Once you have data, it will be displayed in this chart.</p>
                            </div> :

                          <div
                            style={{
                              display: "flex",
                              gap: "26px",
                              flexDirection: "column",
                              width: "70%",
                            }}
                          >
                             {subItem.selectedColumnsNewForStatus.map((column, index) => {
             
                    
                             return ( <div key={index} style={{ marginBottom: "10px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "24px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      background: getBgSquareColor(
                                        column,
                                        subItem.selectedColor
                                      ),
                                      borderRadius: "4px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      color: "#202223",
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      fontFamily: "Graphie-SemiBold",
                                    }}
                                  >
                                    {column}
                                  
                                  </div>
                                </div>
                                <p
                                  style={{
                                    fontSize: "45px",
                                    fontWeight: "700",
                                    color: "#202223",
                                    fontFamily: "Graphie-Bold",
                                  }}
                                >
                                    {getCountForStatusMultiValue(subItem.selectedColumns, subItem)}
                                </p>
                                {subItem.selectedColumnsNewForStatus.length - 1 > index && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgba(201, 204, 207, 0.7)",
                                    }}
                                  ></div>
                                )}
                              </div>)
                              })}
                          </div> }
                        </div>
                      );
                    }   
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div id="content-id">
        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          {serviceReportViewData.map((item ) => {
            return (
              <div style={{ height: item.height, marginTop: "20px" }}>
               <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#818181",
                      textAlign: "left",
                      marginBottom: "0px",
                      fontFamily: "Graphie-SemiBold",
                    }}
                  >
                    {item.title}
                  </p>
                  <p
                  id='date-element'
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#818181",
                      fontFamily: "Graphie-SemiBold",
                      textAlign: "right",
                      paddingRight:"10px" ,
                      marginBottom:"0px" ,
                     
                    }}
                  >
                  </p>
                </div>
                <div style={{ position: "relative" }}>
                  {item.boxes.map((subItem) => {
                    if (subItem.type === "Bar Chart") {
                      
                    const dataset = subItem.horizontal ? getDataSetForHorizontalBarChartStatus(subItem).sort((a, b) => a.data[0] - b.data[0]) : getDataSetForVerticalBarChartStatus(subItem).sort((a, b) => a.data[0] - b.data[0]);
                    const dataNotPresent = dataset.every(item => item.data[0] === 0);
                   
                    return (
                        <div
                          style={{
                            width: subItem.size.width,
                            height: subItem.size.height,
                            position: "absolute",
                            left: subItem.position.x,
                            top: subItem.position.y,
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: dataNotPresent ? 'center' :"flex-end",
                            alignItems: "center",
                          }}
                        >
                          {subItem.horizontal ? dataNotPresent ? <div style={{maxWidth:"190px"}}>
                            <div
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  lineHeight: "33.6px",
                                  color: "#202223",
                                  textAlign: "left",
                                  fontFamily: "Graphie-Regular",
                                }}
                              >
                                {subItem.heading}
                              </span>
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip description={subItem.description} />
                                )}
                              </span>
                            </div>
                            <div>
                              <div><ChartColumnSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" ,fontFamily: "Graphie-SemiBold"}}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" ,fontFamily: "Graphie-Light" }}>Once you have data, it will be displayed in this chart.</p>
                            </div>
                              
                             
                          </div> :(
                            <BarChartHorizontal
                              dataset={dataset}
                              stepsize={getStepSizeForVerticalBarChartStatus(
                                subItem
                              )}
                              max={getMaxForVerticalBarChartStatus(subItem)}
                              title={subItem.heading}
                              description={subItem.description}
                              toolTipData={getTooltipData(subItem)}
                              previousData={previousData}
                            />
                          ) : (
                            dataNotPresent ? <div style={{maxWidth:"190px"}}>
                            <div
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  lineHeight: "33.6px",
                                  color: "#202223",
                                  textAlign: "left",
                                  fontFamily: "Graphie-Regular",
                                }}
                              >
                                {subItem.heading}
                              </span>
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip description={subItem.description} />
                                )}
                              </span>
                            </div>
                            <div>
                              <div><ChartColumnSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold", }}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" , fontFamily: "Graphie-Light"}}>Once you have data, it will be displayed in this chart.</p>
                            </div>
                              
                             
                          </div> :
                            <BarChartVertical
                              dataset={dataset}
                              stepsize={getStepSizeForVerticalBarChartStatus(
                                subItem
                              )}
                              max={getMaxForVerticalBarChartStatus(subItem)}
                              title={subItem.heading}
                              description={subItem.description}
                              toolTipData={getTooltipData(subItem)}
                              previousData={previousData}
                            />
                          )}
                        </div>
                      );
                    }

                    if (subItem.type === "Pie Chart") {
                      const title = subItem.heading;
                      const dataset = getPieChartDataSetStatus(subItem);
                      const bgSet = getPieChartBgStatus(subItem);
                      const pieChartLabel = getPieChartLabelStatus(subItem);
                      const borderColorSetPie =
                        getPieChartBorderStatus(subItem);
                      const description = subItem.description;
                      const allElementsAreZero = dataset.every((item) => item === 0);
                  

                      return (
                        <div
                          style={{
                            width: subItem.size.width,
                            height: subItem.size.height,
                            position: "absolute",
                            left: subItem.position.x,
                            top: subItem.position.y,
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: allElementsAreZero ? 'center':"flex-end",
                            alignItems: "center",
                          }}
                        > 

                        {allElementsAreZero? <div style={{maxWidth:"190px"}}>
                            <div
                              style={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  lineHeight: "33.6px",
                                  color: "#202223",
                                  textAlign: "left",
                                  fontFamily: "Graphie-Regular",
                                }}
                              >
                                {subItem.heading}
                              </span>
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip description={subItem.description} />
                                )}
                              </span>
                            </div>
                            <div>
                              <div><ChartPieSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" , fontFamily: "Graphie-Light" }}>Once you have data, it will be displayed in this chart.</p>
                            </div>
                              
                             
                          </div>:
                          <PieChart
                            title={title}
                            dataset={dataset}
                            bgSet={bgSet}
                            pieChartLabel={pieChartLabel}
                            borderColorSetPie={borderColorSetPie}
                            description={description}
                            mobileView={mobileView}
                          /> }
                        </div>
                      );
                    }

                    if (subItem.type === "Value Chart") {
                      const description = getDescriptionForColumn(
                        subItem.column
                      );
                      const valueChartCount = getStatusCountForValueChart(
                        subItem.selectedColumnsForStatus,
                        subItem.column
                      );
                   
                      return (
                        <div
                          style={{
                            width: subItem.size.width,
                            height: subItem.size.height,
                            position: "absolute",
                            left: subItem.position.x,
                            top: subItem.position.y,
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {valueChartCount === 0 ?  <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  color: "#6d7175",
                                  marginBottom: "6px",
                                  // fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                {subItem.column}{" "}
                                {getColumnTitleForTextChart(
                                  subItem.selectedColumnsForStatus
                                )}
                                <span>
                                  {description.length > 0 && (
                                    <Tooltip
                                      placement="top"
                                      title={description}
                                    >
                                      {" "}
                                      <InfoCircleOutlined
                                        style={{ fontSize: "14px" }}
                                      />{" "}
                                    </Tooltip>
                                  )}
                                </span>
                              </p>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "24px",
                                  fontWeight: "600",
                                  color: "#202223",
                                  marginBottom: "6px",
                                  fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                <p><ChartColumnSvg/> <span  style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</span></p>
                              </p>
                            </div>
                          </div>:
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  color: "#6d7175",
                                  marginBottom: "6px",
                                  // fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                {subItem.column}{" "}
                                {getColumnTitleForTextChart(
                                  subItem.selectedColumnsForStatus
                                )}
                                <span>
                                  {description.length > 0 && (
                                    <Tooltip
                                      placement="top"
                                      title={description}
                                    >
                                      {" "}
                                      <InfoCircleOutlined
                                        style={{ fontSize: "14px" }}
                                      />{" "}
                                    </Tooltip>
                                  )}
                                </span>
                              </p>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  fontSize: "24px",
                                  fontWeight: "600",
                                  color: "#202223",
                                  marginBottom: "6px",
                                  fontFamily: "Graphie-SemiBold",
                                }}
                              >
                                {valueChartCount}
                              </p>
                            </div>
                          </div> }
                        </div>
                      );
                    }

                    if (subItem.type === "Multi Value Chart") {
                      const allCountsAreZero = subItem.selectedColumnsNewForStatus.every(
                        (column) => getCountForStatusMultiValue(subItem.selectedColumns, subItem) === 0
                      );
                      return (
                        <div
                          style={{
                            width: subItem.size.width,
                            height: subItem.size.height,
                            position: "absolute",
                            left: subItem.position.x,
                            top: subItem.position.y,
                            background: "white",
                            border: "1px solid #E3E3E3",
                            borderRadius: "8px",
                            padding: "10px",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "44px",
                          }}
                        >
                          <div
                            style={{
                              width: "70%",
                              borderBottom: allCountsAreZero ?'' :
                                "1px solid rgba(201, 204, 207, 0.7)",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontSize: "24px",
                                fontWeight: "700",
                                color: "#202223",
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                                width: '90%',
                                fontFamily: "Graphie-Regular",
                                borderBottom: allCountsAreZero ?'1px solid rgba(201, 204, 207, 0.7)' :
                                "",
                              }}
                            >
                              {subItem.heading}
                              <span>
                                {subItem.description.length > 0 && (
                                  <CustomTooltip
                                    description={subItem.description}
                                  />
                                )}
                              </span>
                            </p>
                          </div>

                          {allCountsAreZero ?   <div  style={{maxWidth:"190px"}}>
                              <div><ChartPieSvg/></div>
                              <p style={{color:'rgba(32, 34, 35, 0.7)' , fontWeight: 500 , fontSize:"14px" , fontFamily: "Graphie-SemiBold" }}>You haven't any data yet!</p>
                              <p style={{color:'rgba(109, 113, 117, 1)' , fontWeight: 400 , fontSize:"12px" , fontFamily: "Graphie-Light" }}>Once you have data, it will be displayed in this chart.</p>
                            </div> :

                          <div
                            style={{
                              display: "flex",
                              gap: "26px",
                              flexDirection: "column",
                              width: "70%",
                            }}
                          >
                             {subItem.selectedColumnsNewForStatus.map((column, index) => {
             
                    
                             return ( <div key={index} style={{ marginBottom: "10px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "24px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                      background: getBgSquareColor(
                                        column,
                                        subItem.selectedColor
                                      ),
                                      borderRadius: "4px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      color: "#202223",
                                      fontSize: "20px",
                                      fontWeight: "600",
                                      fontFamily: "Graphie-SemiBold",
                                    }}
                                  >
                                    {column}
                                  
                                  </div>
                                </div>
                                <p
                                  style={{
                                    fontSize: "45px",
                                    fontWeight: "700",
                                    color: "#202223",
                                    fontFamily: "Graphie-Bold",
                                  }}
                                >
                                    {getCountForStatusMultiValue(subItem.selectedColumns, subItem)}
                                </p>
                                {subItem.selectedColumnsNewForStatus.length - 1 > index && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgba(201, 204, 207, 0.7)",
                                    }}
                                  ></div>
                                )}
                              </div>)
                              })}
                          </div> }
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
        </div>
      )}
    </div>
    </div>
  );
};
